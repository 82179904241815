import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/OldModal/actions';

import { MODAL_TYPE_NEAR_ME_MAP } from 'core/modules/OldModal/types';
import { INSTORE_ALL_OFFERS_MAP_MODAL_TRIGGER } from '../actions/types';

function* showInstoreAllOffersMapModal() {
  yield put(showModal(MODAL_TYPE_NEAR_ME_MAP));
}

export default function* instoreAllOffersMapModalSaga() {
  yield takeEvery(INSTORE_ALL_OFFERS_MAP_MODAL_TRIGGER, showInstoreAllOffersMapModal);
}
