import { takeEvery, put } from 'redux-saga/effects';

import { showModal } from 'core/modules/OldModal/actions';
import { MODAL_TYPE_INSTORE_SMS_NUMBER } from 'core/modules/OldModal/types';

import {
  INSTORE_SMS_NUMBER_MODAL_TRIGGER,
} from '../actions/types';

function* showInstoreSMSNumberModal() {
  yield put(showModal(MODAL_TYPE_INSTORE_SMS_NUMBER));
}

export default function* instoreSMSNumberModalSaga() {
  yield takeEvery(INSTORE_SMS_NUMBER_MODAL_TRIGGER,
    showInstoreSMSNumberModal);
}
