import shuffle from 'lodash/shuffle';
import { makeArrayEvenLength } from 'core/utils/array';
import { SHOP_WIFI_STORES_GET_SUCCESS, SHOP_WIFI_STORES_GET_FAILURE } from '../actions/types';
import shopWifiStoresConfig from '../configs';

const initialState = {
  config: shopWifiStoresConfig,
  merchants: [],
  isLoading: false,
  isLoaded: false,
};

const shopWifiStores = (state = initialState, action) => {
  switch (action.type) {
    case SHOP_WIFI_STORES_GET_SUCCESS: {
      const placements = action.payload.response;
      const merchants = placements.map(placement => placement.merchant);
      return {
        ...state,
        merchants: shuffle(makeArrayEvenLength(merchants)),
        isLoading: false,
        isLoaded: true,
      };
    }
    case SHOP_WIFI_STORES_GET_FAILURE: {
      return {
        ...state,
        merchants: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === SHOP_WIFI_STORES_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default shopWifiStores;
