import React from 'react';
import { useCheckIfMatchMQ } from 'core/modules/MediaQueries/hooks/useCheckIfMatchMQ';

function ContentWrap(props) {
  const { ignoreWrap, children, className = '' } = props;
  const { isMatchingMQ } = useCheckIfMatchMQ(props);

  if (ignoreWrap || !isMatchingMQ) {
    return className ? <div className={className}>{children}</div> : children;
  }

  return (
    <div className={`mn_contentWrap ${className}`}>
      {children}
    </div>
  );
}

export default ContentWrap;
