import React from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from 'core/selectors/app';

import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import HeaderHolidayImage from 'core/modules/Header/HeaderHolidayImage/HeaderHolidayImage';
import SearchBar from 'core/modules/SearchBar/SearchBar';
import NavigationTOF from 'org/modules/NavigationTOF/NavigationTOF';
import ContentWrap from 'core/modules/Partials/ContentWrap/ContentWrap';
import SidebarTrigger from '../SidebarTrigger/SidebarTrigger';
import MainLogo from '../MainLogo/MainLogo';
import SearchBarTrigger from '../SearchBarTrigger/SearchBarTrigger';
import AccountNavigation from '../AccountNavigation/AccountNavigation';
import AcquisitionPageNavigation from '../AcquisitionPageNavigation/AcquisitionPageNavigation';
import SkipHeader from '../SkipHeader/SkipHeader';

import './HeaderMainBar.scss';

function HeaderMainBar({
  sidebarTriggerClickHandler, toggleSearchBar, isSearchBarOpen, setRef, isSidebarOpen, closeSideBar,
}) {
  const pageName = useSelector(selectPageName);
  const isAcqPage = pageName === 'acq';

  return (
    <div className="mn_headerMainBar" ref={setRef}>
      <SkipHeader />
      <HeaderHolidayImage />
      <ContentWrap>
        <MainLogo />
        {isAcqPage &&
          <ShowOnMQ tablet desktop>
            <AcquisitionPageNavigation />
          </ShowOnMQ>
        }
        <ShowOnMQ xl xxl>
          <div className="mn_searchAndNavWrap">
            <NavigationTOF />
            <SearchBar />
          </div>
        </ShowOnMQ>
        <ShowOnMQ tablet desktop>
          <AccountNavigation closeSideBar={closeSideBar} />
        </ShowOnMQ>

        <ShowOnMQ mobile>
          <SearchBarTrigger
            ariaExpanded={isSearchBarOpen}
            onClick={toggleSearchBar}
          />
        </ShowOnMQ>
        <ShowOnMQ mobile tablet l>
          <SidebarTrigger isSidebarOpen={isSidebarOpen} sidebarTriggerClickHandler={sidebarTriggerClickHandler} />
        </ShowOnMQ>
      </ContentWrap>
    </div>
  );
}

export default HeaderMainBar;
