import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { shopWifiStores as orgConfig } from 'org/configs/modules.config';
import { SHOP_WIFI_STORES_GET_SUCCESS, SHOP_WIFI_STORES_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_type_id: 58,
        content_group_id: 1011,
        limit: 6,
      },
      options: {
        success: SHOP_WIFI_STORES_GET_SUCCESS,
        failure: SHOP_WIFI_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    useAddToFavorite: true,
    checkShouldUseCarousel: (activeMQ) => (['XS', 'S'].includes(activeMQ)),
    carousel: {},
    sectionTitle: 'Purchase inflight Wi-Fi and earn miles shopping stores like these:',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
