import React from 'react';
import { isDesktop } from 'core/utils/helpers/browser';
import { MediaQueries, ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import AdBlockWarning from 'core/modules/AdBlockWarning/AdBlockWarning';
import PreviewMallTool from 'core/modules/PreviewMallTool/PreviewMallTool';
import RecentStoresFlyout from 'core/modules/RecentStoresFlyout/RecentStoresFlyout';
import ModalRoot from 'core/modules/Modal/ModalRoot';
import ModalProvider from 'core/modules/Modal/ModalProvider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Wrapper(props) {
  return (
    <MediaQueries>
      <ModalProvider>
        <div className="mn_pageWrapper">
          <ShowOnMQ tablet desktop>{isDesktop && <AdBlockWarning />}</ShowOnMQ>
          <PreviewMallTool />
          <Header />
          <div className="mn_pageBody" id="mn_pageBody">
            <div className="mn_pageBodyContent">{props.children}</div>
            <ShowOnMQ tablet desktop><RecentStoresFlyout /></ShowOnMQ>
            <Footer />
          </div>
          <div className="mn_modalMainWrapper" />
        </div>
        <ModalRoot />
      </ModalProvider>
    </MediaQueries>
  );
}

export default Wrapper;
