import { takeEvery, put } from 'redux-saga/effects';

import { INSTORE_MULTI_LINK_CLICK } from '../actions/types';
import {
  INSTORE_MULTI_LINK_MODAL_TRIGGER,
} from '../../../OldModal/components/InstoreModals/MultiLinkModal/actions/types';

function* putInstoreMultiLinkModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: INSTORE_MULTI_LINK_MODAL_TRIGGER,
    data,
  });
}

export default function* instoreMultiLinkOverlayClick() {
  yield takeEvery(INSTORE_MULTI_LINK_CLICK, putInstoreMultiLinkModalAction);
}
