import merge from 'lodash/merge';
import { combineReducers } from 'redux';
import coreReducers from 'core/reducers';

// Org reducers
import homeSignInNag from 'org/modules/HomeSignInNag/reducers';
import shopWifiStores from 'org/modules/ShopWifi/components/ShopWifiStores/reducers';
// Home page test reducers
import earnStores from 'org/modules/HomeTest/components/EarnStores/reducers';
import marketingTopBanner from 'org/modules/HomeTest/components/MarketingTopBanner/reducers';

// Top level org config
import config from 'org/configs/org.config';

// Not necessary for the moment but putting it in for structural reasons
export default combineReducers(merge(coreReducers, {
  orgConfig: () => config,
  earnStores,
  homeSignInNag,
  marketingTopBanner,
  shopWifiStores,
}));
