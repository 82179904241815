import { all, call } from 'redux-saga/effects';
import values from 'lodash/values';
import coreSagas from 'core/sagas';
import searchHybridFormSaga from 'core/modules/HybridSearchForm/sagas';
import instoreHiwModalSaga from 'core/modules/OldModal/components/InstoreHiwModal/sagas';
import instoreOfferModalSaga from 'core/modules/OldModal/components/InstoreOfferModal/sagas';
import instoreAllOffersMapModalSaga from 'core/modules/OldModal/components/InstoreAllOffersMapModal/sagas';
import nearMeMapOverlayClickSaga from 'core/modules/Instore/OffersSection/sagas/InstoreAllOffersMapOverlayClick';
import instoreOfferOverlayLinkSaga from 'core/modules/Instore/OffersSection/sagas/instoreOfferOverlayClick';
import instoreManageCardsModal from 'core/modules/OldModal/components/InstoreModals/ManageCardsModal/sagas';
import instoreAddNewCardModal from 'core/modules/OldModal/components/InstoreModals/AddNewCardModal/sagas';
import instoreSMSNumberModal from 'core/modules/OldModal/components/InstoreModals/SMSNumberModal/sagas';
import instoreMultiLinkModal from 'core/modules/OldModal/components/InstoreModals/MultiLinkModal/sagas';
import instoreManageCardsOverlayClick from 'core/modules/Instore/OffersSection/sagas/instoreManageCardsOverlayClick';
import instoreAddNewCardOverlayClick from 'core/modules/Instore/OffersSection/sagas/instoreAddNewCardOverlayClick';
import instoreSMSNumberOverlayClick from 'core/modules/Instore/OffersSection/sagas/instoreSMSNumberOverlayClick';
import instoreMultiLinkOverlayClick from 'core/modules/Instore/OffersSection/sagas/instoreMultiLinkOverlayClick';
import favoritesSaga from './favorites';

const combinedSagas = {
  ...coreSagas,
  searchHybridFormSaga,
  favoritesSaga,
  instoreHiwModalSaga,
  instoreOfferModalSaga,
  instoreManageCardsModal,
  instoreAddNewCardModal,
  instoreSMSNumberModal,
  instoreMultiLinkModal,
  instoreOfferOverlayLinkSaga,
  instoreAllOffersMapModalSaga,
  nearMeMapOverlayClickSaga,
  instoreManageCardsOverlayClick,
  instoreAddNewCardOverlayClick,
  instoreSMSNumberOverlayClick,
  instoreMultiLinkOverlayClick,
};

export default function* rootSaga() {
  yield all(values(combinedSagas).map(saga => call(saga)));
}
