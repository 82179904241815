import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { earnStores as orgConfig } from 'org/configs/modules.config';
import { CACHE_STORAGE } from 'core/utils/cache/base';
import { EARN_STORES_GET_SUCCESS, EARN_STORES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 34388,
        limit: 8,
        sort_by: 'ranking',
        fields: [
          'merchant.logoUrls',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.id',
          'merchant.name',
          'merchant.clickUrl',
        ],
      },
      options: {
        success: EARN_STORES_GET_SUCCESS,
        failure: EARN_STORES_GET_FAILURE,
        cache: {
          isEnabled: true, isPublishedData: true, type: CACHE_STORAGE,
        },
      },
    },
    carousel: {
      breakpoints: {
        l: { hasDots: false, hasArrows: true },
      },
    },
    secTitle: 'Earn miles at stores like these',
    useAddToFavorite: true,
    useCarousel: false,
    useCarouselOnMediaQueriesOnly: ['M', 'L', 'XL', 'XXL'],
    viewAllLinkCTAText: 'View all stores',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
