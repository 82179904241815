import { takeEvery, put } from 'redux-saga/effects';
import { INSTORE_ADD_NEW_CARD_MODAL_TRIGGER }
  from 'core/modules/OldModal/components/InstoreModals/AddNewCardModal/actions/types';

import {
  INSTORE_ADD_NEW_CARD_CLICK,
} from '../actions/types';

function* putInstoreAddNewCardModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: INSTORE_ADD_NEW_CARD_MODAL_TRIGGER,
    data,
  });
}

export default function* instoreAddNewCardOverlayClick() {
  yield takeEvery(INSTORE_ADD_NEW_CARD_CLICK, putInstoreAddNewCardModalAction);
}
