import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/OldModal/actions';
import { MODAL_TYPE_INSTORE_MULTI_LINK } from 'core/modules/OldModal/types';

import {
  INSTORE_MULTI_LINK_MODAL_TRIGGER,
} from '../actions/types';

function* showInstoreMultiLinkModal() {
  yield put(showModal(MODAL_TYPE_INSTORE_MULTI_LINK));
}

export default function* instoreMultiLinkModalSaga() {
  yield takeEvery(INSTORE_MULTI_LINK_MODAL_TRIGGER,
    showInstoreMultiLinkModal);
}
