export const shopWifiStoresClickAndHoverEvents = [
  {
    selector: '.mn_shopWifiStores .mn_merchantTile',
    click: {
      category: 'Shop Wifi Stores',
      action: 'Tap merchant',
    },
    label: element => element.dataset.merchantName,
  },
];
