import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/OldModal/actions';
import { MODAL_TYPE_INSTORE_ADD_NEW_CARD } from 'core/modules/OldModal/types';

import {
  INSTORE_ADD_NEW_CARD_MODAL_TRIGGER,
} from '../actions/types';

function* showInstoreAddNewCardModal() {
  yield put(showModal(MODAL_TYPE_INSTORE_ADD_NEW_CARD));
}

export default function* instoreAddNewCardModalSaga() {
  yield takeEvery(INSTORE_ADD_NEW_CARD_MODAL_TRIGGER,
    showInstoreAddNewCardModal);
}
