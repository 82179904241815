import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/OldModal/actions';

import { MODAL_TYPE_INSTORE_HIW } from 'core/modules/OldModal/types';
import { INSTORE_HIW_MODAL_TRIGGER } from 'core/modules/OldModal/components/InstoreHiwModal/actions/types';

function* showInstoreHiwModal() {
  yield put(showModal(MODAL_TYPE_INSTORE_HIW));
}

export default function* instoreHiwModalSaga() {
  yield takeEvery(INSTORE_HIW_MODAL_TRIGGER, showInstoreHiwModal);
}
