import { takeEvery, put } from 'redux-saga/effects';

import { INSTORE_OFFERS_CLICK } from 'core/modules/Instore/OffersSection/actions/types';
import { INSTORE_OFFER_MODAL_TRIGGER } from 'core/modules/OldModal/components/InstoreOfferModal/actions/types';

function* putInstoreOfferModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: INSTORE_OFFER_MODAL_TRIGGER,
    data,
  });
}

export default function* instoreOfferOverlayClick() {
  yield takeEvery(INSTORE_OFFERS_CLICK, putInstoreOfferModalAction);
}
